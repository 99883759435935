import {Component, OnInit} from '@angular/core';
import {Roles} from 'app/services/enum.service';
import jwt_decode from 'jwt-decode';
import {UserService} from 'app/services/user-service'

// Metadata
export class IRouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: IChildrenItems[];
    permission: string[];
    show?: boolean = true;
}

export class IChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    permission: string[];
    roles?: Roles[];
    show?: boolean = true;
}

// Menu Items
export const ROUTES: IRouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Kryesore',
        type: 'link',
        icontype: 'fa fa-tachometer',
        permission: ['1'],
    },
    {
        path: '/reports/',
        title: 'Raporte',
        type: 'sub',
        collapse: 'reports',
        icontype: 'fa fa-clipboard',
        permission: ['issuedhealthreportype.view'],
        children: [
            { path: 'reports', title: 'Regjistri i Raporteve', ab: 'RR', permission: ['issuedhealthreportype.view'] },
            { path: 'cancel-report', title: 'Anullo Raport', ab: 'AR', permission: ['report.revoke'] },
            { path: 'initial-reports', title: 'Krijo Raport', ab: 'KR', permission: ['issuedhealthreportype.create'] },
            { path: 'reports-to-be-signed', title: 'Raportet për firmë', ab: 'RPF', permission: ['issuedhealthreportype.view'], roles: [Roles.MjekSpecialistSpital, Roles.Administrator, Roles.MjekKml] },
        ]
    },
    {
        path: '/pak/',
        title: 'Pak',
        type: 'sub',
        collapse: 'pak',
        icontype: 'fa fa-clipboard',
        permission: ['issuedapplicationform.view'],
        children: [
            { path: 'add-pak-application', title: 'Krijo Pak', ab: 'KP', permission: ['issuedapplicationform.view'] },
            { path: 'pak-applications', title: 'Regjistri i Aplikimeve', ab: 'RA', permission: ['issuedapplicationform.create'] },
        ]
    },
    {
        path: '/attestations/',
        title: 'Vertetime',
        type: 'sub',
        collapse: 'attestations',
        icontype: 'fa fa-clipboard',
        permission: ['anamnesiscertificategunspermission.manage', 'gunscertificate.manage', 'capableofworkattestationtype.manage'],
        children: [
            // { path: 'initial-attestations', title: 'Krijo vërtetim', ab: 'KV', permission: ['gunscertificate.manage','capableofworkattestationtype.manage'] },
            { path: 'capable-of-work', title: 'Vërtetim i aftë për punë', ab: 'IAP', permission: ['capableofworkattestationtype.manage'], roles: [Roles.MjekFamilje, Roles.Administrator] },
            //{ path: 'guns-anamnesis', title: 'Regjistri anamnezë për armë', ab: 'LW', permission: ['anamnesiscertificategunspermission.manage'], roles: [Roles.MjekFamilje, Roles.Administrator, Roles.MjekSpecialistPoliklinike] },
            { path: 'guns-attestation', title: 'Vërtetim për armë', ab: 'VA', permission: ['gunscertificate.manage'], roles: [Roles.Administrator, Roles.MjekFamilje] },
            { path: 'guns-anamnesis', title: 'Certifikatë anamnezë për armë', ab: 'LW', permission: ['anamnesiscertificategunspermission.manage'], roles: [Roles.MjekSpecialistPoliklinike, Roles.MjekFamilje, Roles.Administrator] },
        ]
    },
    {
        path: 'attestations/driving-license/',
        title: 'Lejet e drejtimit',
        type: 'sub',
        collapse: 'driving-license',
        icontype: 'nc-icon nc-badge',
        permission: ['driverslicensecertificatedisease.manage'],
        children: [
            { path: 'diseases', title: 'Lista e semundjeve', ab: 'LS', permission: ['driverslicensecertificatedisease.view'] },
            { path: 'driving-license-anamnesis', title: 'Lista e anamnezave', ab: 'LA', permission: ['1'] }
        ]
    },
    {
        path: '/roles/',
        title: 'Rolet',
        type: 'sub',
        collapse: 'roles',
        icontype: 'fa fa-check-circle-o',
        permission: ['roles.manage'],
        children: [
            { path: 'roles', title: 'Lista e roleve', ab: 'LR', permission: ['roles.view'] },
            { path: 'add', title: 'Shto një rol', ab: 'SHR', permission: ['roles.assign'] },
            { path: 'permissions', title: 'Permissions', ab: 'P', permission: ['roles.manage'] },
        ]
    },
    {
        path: '/institutions/',
        title: 'Institucionet',
        type: 'sub',
        collapse: 'institutions',
        icontype: 'nc-icon nc-bank',
        permission: ['institutions.manage'],
        children: [
            { path: 'institutions', title: 'Lista e institucioneve', ab: 'LI', permission: ['institutions.view'] },
            { path: 'add', title: 'Shto një institucion', ab: 'SHI', permission: ['institutions.create'] },
            { path: 'types', title: 'Llojet e institucioneve', ab: 'LI', permission: ['institutions.view'] },
        ]
    },
    {
        path: '/users/',
        title: 'Përdoruesit',
        type: 'sub',
        collapse: 'users',
        icontype: 'fa fa-user-o',
        permission: ['users.manage'],
        children: [
            { path: 'users', title: 'Lista e përdoruesve', ab: 'LP', permission: ['users.manage', 'users.view'] },
            { path: 'add', title: 'Shto një përdorues', ab: 'SHP', permission: ['users.manage', 'users.view'] },
        ]
    },
    {
        path: '/configurations/',
        title: 'Konfigurime',
        type: 'sub',
        collapse: 'health-report',
        icontype: 'fa fa-cog',
        permission: [
            'administrativeunit.manage',
            'city.manage',
            'region.manage',
            'healthreportype.manage',
            'legalmedicalcommission.manage',
            'speciality.manage'
        ],
        children: [
            { path: 'administrative-unit', title: 'Njësitë Administrative', ab: 'NJA', permission: ['administrativeunit.manage'] },
            { path: 'city', title: 'Qytetet', ab: 'Q', permission: ['cityhall.view', 'cityhall.manage'] },
            { path: 'region', title: 'Qarqet', ab: 'Q', permission: ['region.view'] },
            { path: 'health-report', title: 'Tipet e raporteve', ab: 'LR', permission: ['healthreportype.view'] },
            { path: 'forensic-reports', title: 'KML', ab: 'KML', permission: ['legalmedicalcommission.view'] },
            { path: 'specialities', title: 'Specialitetet', ab: 'S', permission: ['speciality.manage'] },
            { path: 'oshksh', title: 'OSHKSH', ab: 'O', permission: ['oshksh.manage'] },
            { path: 'droshksh', title: 'DROSHKSH', ab: 'DO', permission: ['droshksh.manage'] },
            { path: 'njvksh', title: 'NJVKSH', ab: 'NJ', permission: ['njvksh.manage'] },

        ]
    },
    {
        path: '/diagnosis/',
        title: 'Diagnozat',
        type: 'sub',
        collapse: 'diagnosis',
        icontype: 'fa fa-stethoscope',
        permission: ['diagnose.manage', 'diagnoseclass.manage', 'diagnosegroup.manage',],
        children: [
            { path: 'icd-10', title: 'ICD10', ab: 'ICD10', permission: ['diagnose.manage'] },
            { path: 'diagnosis', title: 'Lista e diagnozave', ab: 'LD', permission: ['diagnose.manage'] },
            { path: 'diagnose-class', title: 'Klasat e Diagnozave', ab: 'KD', permission: ['diagnoseclass.manage'] },
            { path: 'diagnose-group', title: 'Grupet e Diagnozave', ab: 'GD', permission: ['diagnosegroup.manage'] },
            { path: 'add', title: 'Shto një diagnozë', ab: 'SHD', permission: ['diagnose.create'] },
        ]
    },
    {
        path: '/logs/',
        title: 'Loget',
        type: 'sub',
        collapse: 'logs',
        icontype: 'fa fa-arrows',
        permission: ['logs.manage'],
        children: [
            { path: 'logs', title: 'Lista e logeve', ab: 'LD', permission: ['logs.manage'] },
        ]
    },
    {
        path: '/statistics/',
        title: 'Statistika',
        type: 'sub',
        collapse: 'statistics',
        icontype: 'fa fa-arrows',
        permission: ['customreports.manage'],
        children: [
            { path: 'custom-reports', title: 'Statistika Raporte', ab: 'LD', permission: ['customreports.manage'] },
            // { path: 'statistic-reports', title: 'Raporte', ab: 'SR', permission: ['customreports.manage'] }
        ]
    },
    {
        path: '/notifications/',
        title: 'Njoftimet',
        type: 'sub',
        collapse: 'notifications',
        icontype: 'fa fa-bell-o',
        permission: ['notification.edit', 'notification.create', 'notification.delete'],
        children: [
            { path: 'notifications', title: 'Lista e njoftimeve', ab: 'LN', permission: ['notification.edit', 'notification.create', 'notification.delete'] },
            { path: 'add', title: 'Shto një njoftim', ab: 'SHN', permission: ['notification.edit', 'notification.create', 'notification.delete'] },
        ]
    },
    {
        path: '/manuals/',
        title: 'Manualet',
        type: 'sub',
        collapse: 'manuals',
        icontype: 'fa fa-book',
        permission: ['manual.edit', 'manual.create'],
        children: [
            { path: 'manuals', title: 'Lista e manualeve', ab: 'LN', permission: ['manual.edit'] },
            { path: 'add', title: 'Shto një manual', ab: 'SHN', permission: ['manual.create'] },
        ]
    },
    {
        path: '/contact/',
        title: 'Kontaktoni',
        type: 'sub',
        collapse: 'contact',
        icontype: 'fa fa-comments',
        permission: ['send.email'],
        children: [
            { path: 'add-contact', title: 'Kontaktoni', ab: 'LN', permission: ['send.email'] },
        ]
    },
    {
        path: '/audit/',
        title: 'Audit raportesh',
        type: 'sub',
        collapse: 'audit',
        icontype: 'fa fa-history',
        permission: ['issuedhealthreportype.audit'],
        children: [
            { path: 'audit-page', title: 'Audit', ab: 'LN', permission: ['issuedhealthreportype.audit'] },
        ]
    },
    {
        path: '/e-albania/',
        title: 'Njoftim e-albania',
        type: 'sub',
        collapse: 'e-albania',
        icontype: 'fa fa-bell-o',
        permission: ['send.notification'],
        children: [
            { path: 'notification', title: 'Dërgo Njoftim', ab: 'LN', permission: ['send.notification'] },
        ]
    }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public permissions: any;
    constructor(private userService: UserService) {

     }

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }


    async ngOnInit() {
        // permissions
   

        const tokenPayload: any = this.getToken();
        tokenPayload.permission
        this.permissions = tokenPayload.permission;
        if (typeof this.permissions === 'string') {
            this.permissions = [this.permissions];
        }
        this.menuItems = ROUTES.filter(menuItem => {
            // loop through all menu item childrens
            menuItem.children?.map((childTab) =>
                // modify item to  show according to role
                childTab.show = this.checkForRole(childTab))
            return menuItem
        });
    }
    private getToken() {
        const token = JSON.parse(localStorage.getItem('currentUser'));
        const tokenPayload: any = jwt_decode(token.access_token);
        return tokenPayload;
    }

    ngAfterViewInit() {
    }


    inArray(permission: any[]) {
        let allowMenuItem: boolean = false;
        if (this.permissions && this.permissions.length > 0) {
            for (let i = 0; i < permission.length; i++) {
                if (this.permissions.some(x => x === permission[i]) || permission[i] == '1') {
                    allowMenuItem = true;
                }
            }
        }
        return allowMenuItem;
    }
    checkForRole(childitem: IChildrenItems) {
        if (!childitem.roles) {
            return true
        }
        return this.userService.checkForRole(childitem.roles)

    }

}


