<form [formGroup]="form" ng-class="{submitted:form.submitted}">
    <div class="card col-12">
        <div class="card-header">
            <h4 *ngIf="title" class="card-title">{{title}}</h4>
            <hr *ngIf="title">
        </div>
        <div class="card-body row">
            <ng-container *ngFor="let item of model">
                <ng-container [ngSwitch]="item.controlType">
                    <ng-container *ngSwitchCase="controlType.input" [ngTemplateOutlet]="input"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.select" [ngTemplateOutlet]="select"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.radio" [ngTemplateOutlet]="radio"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.checkbox" [ngTemplateOutlet]="checkbox"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.textarea" [ngTemplateOutlet]="textarea"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.datepicker" [ngTemplateOutlet]="datepicker"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.multipleSelect" [ngTemplateOutlet]="multipleSelect"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.headline" [ngTemplateOutlet]="headline"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.chips" [ngTemplateOutlet]="chips"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.subHeadline" [ngTemplateOutlet]="subHeadline"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>
                    <ng-container *ngSwitchCase="controlType.break" [ngTemplateOutlet]="break"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>

                    <ng-container *ngSwitchCase="controlType.tel" [ngTemplateOutlet]="tel"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>

                    <ng-container *ngSwitchCase="controlType.file" [ngTemplateOutlet]="file"
                        [ngTemplateOutletContext]="{item:item}"></ng-container>

                </ng-container>
            </ng-container>
            <ng-container *ngIf="sections">
                <div class="accordion col-sm-12 shadow-none mt-5" id="accordionExample">
                    <ng-container *ngFor="let group of sectionFields | keyvalue;  let i = index">
                        <div class="card shadow-none col-12 m-0 p-0">
                            <div class="card-header p-0 m-0" id="headingOne">
                                <h6 class="mb-0 ">
                                    <button class="btn-block w-100 d text-left m-0 py-3 d-flex  justify-content-between"
                                        [ngClass]="(sections[group?.key-1]?.show)?'bg-choosen':'bg-gray'" type="button"
                                        data-toggle="collapse" [attr.data-target]="'#' + sections[group?.key-1]?.class"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        {{sections[group?.key-1]?.name}}
                                    </button>

                                </h6>
                            </div>
                            <div [id]="sections[group?.key-1]?.class"
                                [ngClass]="(sections[group?.key-1]?.show)?'collapse show':'collapse'"
                                aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body row ">
                                    <ng-container *ngFor="let item of group.value">
                                        <ng-container [ngSwitch]="item?.controlType">
                                            <ng-container *ngSwitchCase="controlType.input" [ngTemplateOutlet]="input"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.select" [ngTemplateOutlet]="select"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.radio" [ngTemplateOutlet]="radio"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.checkbox"
                                                [ngTemplateOutlet]="checkbox" [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.textarea"
                                                [ngTemplateOutlet]="textarea" [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.datepicker"
                                                [ngTemplateOutlet]="datepicker" [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.multipleSelect"
                                                [ngTemplateOutlet]="multipleSelect"
                                                [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.headline"
                                                [ngTemplateOutlet]="headline" [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.chips" [ngTemplateOutlet]="chips"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.subHeadline"
                                                [ngTemplateOutlet]="subHeadline"
                                                [ngTemplateOutletContext]="{item:item}">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="controlType.pharagraph" [ngTemplateOutlet]="pharagraph"
                                                          [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.break" [ngTemplateOutlet]="break"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.tel" [ngTemplateOutlet]="tel"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                            <ng-container *ngSwitchCase="controlType.file" [ngTemplateOutlet]="file"
                                                [ngTemplateOutletContext]="{item:item}"></ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="card-footer">
            <button type="submit" *ngIf="hasDraft" (click)="onDraft()" class="btn btn-success">Ruaj Draft</button>
            <button type="submit" *ngIf="hasSave" (click)="onSave()" class="btn btn-success">Ruaj</button>
            <button type="submit" *ngIf="hasModify" (click)="onModify()" class="btn btn-success">Modifiko</button>
            <button type="submit" *ngIf="hasCancel" (click)="onCancel()" class="btn btn-warning">Anullo</button>
            <button type="submit" *ngIf="hasFilter" (click)="onSave()" class="btn btn-success">Filtro</button>
            <button type="submit" *ngIf="hasDownload" (click)="onSave()" class="btn btn-success">Shkarko</button>
            <button type="submit" *ngIf="hasFilter" (click)="onSave(true)" class="btn btn-warning">Pastro
                Fushat</button>
        </div>
    </div>

    <!-- Input template -->
    <ng-template #input let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group">
                <input class="form-control" [formControlName]="item.key" [id]="item.key" [type]="item.type"
                    [value]="item.value" [placeholder]="(item.placeholder) ? item.placeholder : ''"
                    (input)="(item.changeMethod) ? item.changeMethod($event.target.value, form, item.key) : ''">
            </div>
        </div>
    </ng-template>
    <!-- End input template -->

    <!-- Single Select template -->
    <ng-template #select let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group bootstrap-select w-100">
                <ng-select [items]="item.options"
                           [multiple]="item.controlType === ControlType.multipleSelect"
                           [closeOnSelect]="true"
                           [searchable]="true"
                           [selectableGroup]="true"
                           bindLabel="value"
                           [formControlName]="item.key"
                           [virtualScroll]="true"
                           (change)="item.changeMethod($event, form)"
                           [(ngModel)]="(item.value && item.value?.length) ? item.value[0] : item.value"
                           [disabled]="form.get(item.key)?.disabled"
                           [dropdownPosition]="item.position"
                           placeholder="Zgjidh">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/> <span class="ng-option-label" [attr.title]="item.value"> {{ item.value }}</span>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label" [attr.title]="item.value">{{ item.value }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
<!--        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">-->
<!--            <label [attr.for]="item.key">{{item.label}}</label>-->
<!--            <div class="form-group bootstrap-select w-100">-->
<!--                <angular2-multiselect (onSelect)="item?.changeMethod($event, form)" [settings]="getSelectSettings(item)"-->
<!--                    [data]="item.options" [formControlName]="item.key"></angular2-multiselect>-->
<!--            </div>-->
<!--        </div>-->
    </ng-template>
    <!-- End Single Select template -->


    <!-- Textarea template -->
    <ng-template #textarea let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group">
                <textarea class="form-control" style="margin-left: auto;" [id]="item.key"
                    [formControlName]="item.key">{{item.value}}</textarea>
            </div>
        </div>
    </ng-template>
    <!-- End textarea template -->

    <!-- Radio template -->
    <ng-template #radio let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group checkbox-radios" name="item.key" ngDefaultControl [formControlName]="item.key">
                <div *ngFor="let opt of item.options"
                    (change)="(item.changeMethod) ? item.changeMethod($event.target.value, form, item.key) : '' "
                    [id]="opt.key" class="form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" [id]="opt.key" [attr.hidden]="item.hidden" [name]="item.key"
                            [type]="item.type" [value]="opt.key" [checked]="(opt.key === item.value)"
                            [disabled]="item.readonly">
                        {{opt.value}}
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <p class="mt-2" *ngIf="!!item.message"><strong>{{item.message}}</strong></p>
            </div>
        </div>
    </ng-template>
    <!-- End radio template -->

    <!-- Checkbox template -->
    <ng-template #checkbox let-item='item'>
        <div class="col-xl-3 col-sm-12 col-md-6 col-lg-3">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group checkbox-radios">
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" [id]="item.key" [name]="item.key" type="checkbox"
                            [checked]="item.value" ngDefaultControl [formControlName]="item.key"
                            (change)="item?.changeMethod($event.target.value,form,item.key)"> {{item.placeholder}}
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <p class="mt-2" *ngIf="!!item.message"><strong>{{item.message}}</strong></p>
            </div>
        </div>
    </ng-template>
    <!-- End checkbox template -->

    <!-- Datepicker template -->
    <ng-template #datepicker let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group">
                <input class="form-control" name="dp" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="item?.maxDate ? transformDate(item?.maxDate) : null" 
                    #d="ngbDatepicker" (click)="d.toggle()" name="item.key" [formControlName]="item.key">
            </div>
        </div>
    </ng-template>
    <!-- End datepicker template -->

    <!-- new dropdown-->
<!--    <ng-template #multipleSelect let-item='item'>-->
<!--        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">-->
<!--            <label [attr.for]="item.key">{{item.label}}</label>-->
<!--            <angular2-multiselect fixSearchBug (onSelect)="item?.changeMethod($event, form)"-->
<!--                (onDeSelect)="item?.changeMethod($event, form)" [settings]="getSelectSettings(item)"-->
<!--                                  [data]="item.filteredOptions || item.options" [formControlName]="item.key">-->
<!--                <c-search>-->
<!--                    <ng-template>-->
<!--                        <input type="text" (keyup)="onSearch($event, item)" placeholder="Search" style="border: none;width: 100%; height: 100%;outline: none;"/>-->
<!--                    </ng-template>-->
<!--                </c-search>-->
<!--            </angular2-multiselect>-->
<!--        </div>-->
<!--    </ng-template>-->
    <!-- new dropdown -->

    <ng-template #multipleSelect let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="!item?.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group bootstrap-select w-100">
                <ng-select [items]="item.options"
                           [multiple]="item.controlType === ControlType.multipleSelect"
                           [closeOnSelect]="false"
                           [searchable]="true"
                           [selectableGroup]="true"
                           bindLabel="value"
                           [formControlName]="item.key"
                           [(ngModel)]="item.value"
                           placeholder="Zgjidh"
                           [virtualScroll]="true"
                           (change)="item?.changeMethod($event, form)">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/> <span class="ng-option-label" [attr.title]="item.value"> {{ item.value }}</span>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label" [attr.title]="item.value">{{ item.value }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </ng-template>

    <!-- Headline -->
    <ng-template #headline let-item='item'>
        <div class="col-12  mb-2">
            <h4 class="card-title">{{item.value}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </h4>
            <hr>
        </div>
    </ng-template>

    <!-- Sub Headline -->
    <ng-template #subHeadline let-item='item'>
        <div class="col-12">
            <h6 class="card-title m-0">{{item.value}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </h6>
            <hr>
        </div>
    </ng-template>

    <!-- Pharagraph -->
    <ng-template #pharagraph let-item='item'>
        <div class="col-12">
            <p class="card-title m-0 pl-3 mb-2">{{item.value}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </p>
        </div>
    </ng-template>

    <!-- Break -->
    <ng-template #break let-item='item'>
        <div class="col-12 mb-2">
            <hr>
        </div>
    </ng-template>

    <!--Start Chips -->
    <ng-template #chips let-item='item'>
        <h5 class="mt-4 pl-3 d-block" style="width: 100%;">{{item.label}}
            <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                <i class="fa fa-info-circle"></i>
            </span>
        </h5>
        <hr style="width: 100%;">
        <div class=" col-sm-6 col-12 col-lg-3 mb-4 mt-4" *ngFor="let chips of item.options ; index as i">
            <div class="card ">
                <div class="card-body">
                    <div class="d-flex justify-content-between px-md-1">
                        <div>
                            <h5 class="text-primary">Diagnoza {{i+1}}</h5>
                            <p class="mb-0">{{chips.description?chips?.description:chips.value}}</p>
                        </div>
                        <!-- <div class="align-self-center">
                            <button type="button" class="btn btn-danger" (click)="deleteIndex(i)">X</button>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <!--End Chips -->

    <!-- Input template -->
    <ng-template #tel let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.hidden">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group">
                <international-phone-number (change)="item?.changeMethod($event.target.value, form)"
                    [formControlName]="item.key" [id]="item.key" [type]="item.type" placeholder="Enter phone number"
                    [maxlength]="20" [defaultCountry]="'al'">
                </international-phone-number>

            </div>
        </div>
    </ng-template>
    <!-- End input template -->

    <!-- file template -->
    <ng-template #file let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <label [attr.for]="item.key">{{item.label}}
                <span *ngIf="item.tooltip" data-toggle="tooltip" data-placement="top" [title]="item.tooltip">
                    <i class="fa fa-info-circle"></i>
                </span>
            </label>
            <div class="form-group">
                <div *ngIf="item.value && !showFileInput">
                    <p class="d-inline">{{item.value}}</p>
                    <button class="btn btn-primary btn-sm ml-2" (click)="toggleFileInput()">
                        <i class="fa fa-edit"></i>
                    </button>
                </div>
                <input class="form-control" [formControlName]="item.key" *ngIf="showFileInput || !item.value" 
                (change)="onFileSelected($event, item.key)"
                [id]="item.key" type="file" data-toggle="tooltip" 
                [accept] = "item.accepts" 
                data-placement="top" [attr.title]="item.tooltip?item.tooltip:null">
            </div>
        </div>
    </ng-template>
    <!-- End file template -->
</form>
