import { EnumService } from './services/enum.service';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { appInitializer } from './helpers/app.initializer';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AuthenticationService } from './services/authenticationService';
import { CustomAdapter, CustomDateParserFormatter } from './helpers/date-adapter';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RoleGuardService as RoleGuard } from './services/role-guard.service';
import { TransformField } from './pipes/field-transform-pipe';
import { GridService } from './services/grid-service';
import {NgSelectModule} from '@ng-select/ng-select';
import {BnNgIdleService} from 'bn-ng-idle';
import { PermissionService } from './services/permission.service';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: false
        }),
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        ComponentsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AngularMultiSelectModule,
        UiSwitchModule,
        NgSelectModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        TransformField,


    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService]},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        EnumService,
        GridService,
        RoleGuard,
        BnNgIdleService,
        PermissionService
    ],
    exports: [],
    bootstrap: [AppComponent]
})

export class AppModule { }
