<form [formGroup]="form" ng-class="{submitted:form.submitted}">
    <div class="card col-12">
        <div class="card-header">
            <h4 *ngIf="title" class="card-title">{{title}}</h4>
            <hr *ngIf="title">
        </div>
        <div class="card-body row">
            <ng-container *ngFor="let item of model">
                <ng-container *ngIf="reportIncluded(reportType, item.reportType)">
                    <ng-container [ngSwitch]="item.controlType">
                        <ng-container *ngSwitchCase="controlType.input" [ngTemplateOutlet]="input"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.select" [ngTemplateOutlet]="select"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.radio" [ngTemplateOutlet]="radio"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.checkbox" [ngTemplateOutlet]="checkbox"
                            [ngTemplateOutletContext]="{item:item}">
                        </ng-container>
                        <ng-container *ngSwitchCase="controlType.textarea" [ngTemplateOutlet]="textarea"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.datepicker" [ngTemplateOutlet]="datepicker"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.multipleSelect" [ngTemplateOutlet]="multipleSelect"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.headline" [ngTemplateOutlet]="headline"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.chips" [ngTemplateOutlet]="chips"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.tel" [ngTemplateOutlet]="tel"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.file" [ngTemplateOutlet]="file"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>
                        <ng-container *ngSwitchCase="controlType.tags" [ngTemplateOutlet]="tags"
                            [ngTemplateOutletContext]="{item:item}"></ng-container>

                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="card-footer">
            <button type="submit" *ngIf="hasSave" (click)="onSave()" class="btn btn-success">Ruaj</button>
            <button type="submit" *ngIf="hasModify" (click)="onModify()" class="btn btn-success">Modifiko</button>
            <button type="submit" *ngIf="hasCancel" (click)="onCancel()" class="btn btn-warning">Anullo</button>
        </div>
    </div>

    <!-- Input template -->
    <ng-template #input let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group">
                <input class="form-control" (change)="item?.changeMethod($event.target.value, form)" min="1"
                    [formControlName]="item.key" [id]="item.key" [type]="item.type" data-toggle="tooltip"
                    data-placement="top" [attr.title]="item.tooltip?item.tooltip:null" [value]="item.value">
            </div>
        </div>
    </ng-template>
    <!-- End input template -->

    <!-- Tags template -->
    <ng-template #tags let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <input type="hidden" [formControlName]="item.key"  (change)="item?.changeMethod($event.target.value, form)">
            <div class="form-group repeater-wrapper">
                <input class="form-control repeater" [disabled]="item.disabled" [ngClass]="textValues[item.key]?.length < 2 ? 'invalidField': ''" [id]="item.key" (input)="onInputChange(item.key, $event.target.value)"  placeholder="Shto NID">
                <div class="repeater-button btn-success" [ngClass]="item.disabled ? 'disabled-add-button': ''" (click)="addTextValue(item.key)">
                    <i class="fa fa-plus"></i> Shto
                </div>
                <div *ngFor="let text of textValues[item.key]" class="form-group repeater-item">
                    <div class="float-left">{{text}}</div>
                    <div class="float-right" [ngClass]="item.disabled ? 'disabled-delete-btn': ''" (click)="removeTextValues(item.key, text, item.disabled)">
                        <i class="fa fa-trash-o"></i>
                    </div>
                </div>
                <p class="validation" *ngIf="textValues[item.key]?.length < 1"> Kërkohet minimumi 1 NID</p>
            </div>
        </div>
    </ng-template>
    <!-- End tags template -->

    <!-- Single Select template -->
    <ng-template #select let-item='item'>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
                <label [attr.for]="item.key">{{item.label}}</label>
                <div class="form-group bootstrap-select w-100">
                    <ng-select [items]="item.options"
                               [multiple]="item.controlType === ControlType.multipleSelect"
                               [closeOnSelect]="true"
                               [searchable]="true"
                               bindLabel="value"
                               [selectableGroup]="true"
                               [formControlName]="item.key"
                               placeholder="Zgjidh"
                               [virtualScroll]="true"
                               (change)="item.changeMethod($event, form)"
                               [disabled]="form.get(item.key)?.disabled"
                               [(ngModel)]="(item.value && item.value?.length) ? item.value[0] : item.value">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/> <span class="ng-option-label" [attr.title]="item.value"> {{ item.value }}</span>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <span class="ng-value-label" [attr.title]="item.value">{{ item.value }}</span>
                        </ng-template>
                    </ng-select>
<!--                        <angular2-multiselect [settings]="getDropdownSettings(item)"-->
<!--                                              (onSelect)="item?.changeMethod($event, form)" [data]="item.options" [formControlName]="item.key"-->
<!--                                              [disabled]="item.disabled"></angular2-multiselect>-->

                </div>
            </div>
    </ng-template>
    <!-- End Single Select template -->

    <!-- Textarea template -->
    <ng-template #textarea let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group">
                <textarea class="form-control" style="margin-left: auto;" [id]="item.key" [maxlength]="item.max"
                    [formControlName]="item.key">{{item.value}}</textarea>
            </div>
        </div>
    </ng-template>
    <!-- End textarea template -->

    <!-- Radio template -->
    <ng-template #radio let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group checkbox-radios" name="item.key" ngDefaultControl [formControlName]="item.key">
                <div *ngFor="let opt of item.options" [id]="opt.key" class="form-check-radio">
                    <label class="form-check-label">
                        <input (change)="item.changeMethod($event.target.value, form)" class="form-check-input"
                            [id]="opt.key" [disabled]="item.disabled" [name]="item.key" [type]="item.type" [checked]="opt.key === item.value"
                            [value]="opt.key"> {{opt.value}}
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- End radio template -->

    <!-- Checkbox template -->
    <ng-template #checkbox let-item='item'>
        <div class="col-xl-3 col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group checkbox-radios">
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" [id]="item.key" [name]="item.key" type="checkbox"
                            [checked]="item.value" ngDefaultControl [formControlName]="item.key"
                            (change)="item?.changeMethod($event.target.value, form)"> {{item.placeHolder}}
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- End checkbox template -->

    <!-- Datepicker template -->
    <ng-template #datepicker let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group">
                <input class="form-control" name="dp" ngbDatepicker
                    [minDate]="item.key === 'startDate' ? minDate : {year: 1920, month:1, day: 1}"
                    [maxDate]="item.key === 'startDate' ? maxDate : null" #d="ngbDatepicker" (click)="d.toggle()"
                    name="item.key" [formControlName]="item.key" (ngModelChange)="item?.changeMethod($event, form)">
            </div>
        </div>
    </ng-template>
    <!-- End datepicker template -->

    <!-- new dropdown-->
<!--    <ng-template #multipleSelect let-item='item'>-->
<!--        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">-->
<!--            <label [attr.for]="item.key">{{item.label}}</label>-->
<!--            <div class="form-group bootstrap-select w-100">-->
<!--                <angular2-multiselect [settings]="getDropdownSettings(item)" [data]="item.options"-->
<!--                    [formControlName]="item.key" (onSelect)="item?.changeMethod($event, form)" [disabled]="item.disabled"-->
<!--                                      (onDeSelect)="item?.changeMethod($event, form)"-->
<!--                                      (onDeSelectAll)="item?.changeMethod($event, form)">-->
<!--                </angular2-multiselect>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-template>-->
    <ng-template #multipleSelect let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="item.show">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group bootstrap-select w-100">
                <ng-select [items]="item.options"
                           [multiple]="item.controlType === ControlType.multipleSelect"
                           [closeOnSelect]="false"
                           [searchable]="true"
                            bindLabel="value"
                           [formControlName]="item.key"
                           [(ngModel)]="item.value"
                           placeholder="Zgjidh"
                           (change)="item?.changeMethod($event, form)"
                           [virtualScroll]="true">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/> <span class="ng-option-label" [attr.title]="item.value"> {{ item.value }}</span>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label" [attr.title]="item.value">{{ item.value }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </ng-template>
    <!-- new dropdown -->

    <!-- Headline -->
    <ng-template #headline let-item='item'>
        <div class="col-12  mb-2">
            <h4 class="card-title">{{item.value}}</h4>
            <hr>
        </div>
    </ng-template>
    <!--Start Chips -->
    <ng-template #chips let-item='item'>
        <ng-container *ngIf="item.show">
            <h5 class="mt-4 pl-3 d-block" style="width: 100%;">{{item.label}}</h5>
            <hr style="width: 100%;">
            <div class=" col-sm-6 col-12 col-lg-3 mb-4 mt-4" *ngFor="let chips of item.options ; index as i">
                <div class="card ">
                    <div class="card-body">
                        <div class="d-flex justify-content-between px-md-1">
                            <div>
                                <h5 class="text-primary">Diagnoza {{i+1}}</h5>
                                <p class="mb-0">{{chips.description?chips?.description:chips.value}}</p>
                            </div>
                            <!-- <div class="align-self-center">
                                <button type="button" class="btn btn-danger" (click)="deleteIndex(i)">X</button>

                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <!--End Chips -->

    <!-- Input template -->
    <ng-template #tel let-item='item'>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <label [attr.for]="item.key">{{item.label}}</label>
            <div class="form-group">
                <international-phone-number (change)="item?.changeMethod($event.target.value, form)"
                    [formControlName]="item.key" [id]="item.key" [type]="item.type" placeholder="Enter phone number"
                    [maxlength]="20" [defaultCountry]="'al'">
                </international-phone-number>
            </div>
        </div>
    </ng-template>
    <!-- End input template -->
</form>
