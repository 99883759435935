import { FormGroup } from '@angular/forms';
import { FormService } from './../../services/form.service';
import { Component, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ControlType } from './control-type-enum';
import { SpecialityType } from 'app/services/enum.service';
import { DropdownSettings } from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import {DPGJCModel, DPTModel} from '../../reports/reports-model';

declare var $: any;

export interface IOptions {
  key: any;
  value: any;
}
export interface Section {
  section: number;
  title: string;

}

export enum SelectType {
  select,
  multipleSelect
}
export enum SelectionChoice {
  add, remove
}
export class SelectDowpdownIdsModel {
  select: string[];
  multipleSelect: string[]
}

export interface SelectedFiles {
  formKey: string;
  selectedFile: string;
}
export class IForm {
  value: any;
  key: string;
  label: string;
  placeholder?: string;
  required: boolean;
  order?: number;
  controlType: ControlType;
  type: string;
  options?: IOptions[];
  hidden?: boolean = false;
  readonly?: boolean = false;
  validators?: any;
  authorSpeciality?: SpecialityType;
  belongTo?: number | number[] | undefined;
  settings?:IDropdownSettings;
  nestedKeyForDropdowns?:any;
  accepts?: string;
  changeMethod?(values: any, form: any, key?: string): any;
  position?: string;
  maxDate?: Date;
  message?: string;
  tooltip?: string;
}

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss']
})
export class FormComponentComponent implements OnInit {
  // @Input
  @Input() sections: Section;
  /*
  * Form Model
  */
  @Input() model: IForm[];
  /*
  * Form Title
*/
  @Input() title: string;
  /*

  * Has save button
  */
  @Input() hasSave: boolean;
  /*
  * Has draft button
  */
  @Input() hasDraft: boolean;
  /*
  * Has filter button
  */
  @Input() hasFilter: boolean;
  
  /*
  * Has cancel button
  */
  @Input() hasCancel: boolean;
  /*
  * Has modify button
  */
  @Input() hasModify: boolean;

  @Input() hasDownload: boolean;
  /**
   * Has modify button
   */
  @Input() validateDisabled: boolean;
  /*
  * Save event emitter
  */
  @Output() save = new EventEmitter();
  /*
  * Cancel event emitter
  */
  @Output() cancel = new EventEmitter();
  /*
  * Modify event emitter
  */
  @Output() modify = new EventEmitter();

  /*
* Modify event emitter
*/
  @Output() draft = new EventEmitter();
  /*
  * Form Group
  */
  public form: FormGroup;
  /*
  * Multiple select settings
  */
  protected dropdownSettings = {};

  /*
  * Single select settings
  */
  protected singleSelect = {};

  /*
  * Control Type
  */
  public controlType = ControlType;

  /*
  * Grouped Sections
  */
  public sectionFields;
  /*
  * dpgjc model
  */
  @Input() dpgjc: DPGJCModel;
  /*
  * dpgjc model
  */
  @Input() dpt: DPTModel[];

  public showFileInput = false;
  public selectedFiles: SelectedFiles[] = [];
  constructor(private _formService: FormService, private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.sections) {
      this.sectionFields = this.model.filter(field => field.belongTo)
      this.form = this._formService.toFormGroup(this.model);
      this.model = this.model.filter(field => !field.belongTo)
      // Group by section as key to the person array
      this.sectionFields = this.groupBy(this.sectionFields, 'belongTo');
    }
    else {
      this.form = this._formService.toFormGroup(this.model);
    }

    if (this.dpgjc) {
      this.updateValuesByDpgjc();
    }
    if (this.dpt) {
      this.updateValuesByDpt();
    }

    this.dropdownSettings = {
      singleSelection: false,
      text: "Zgjidh",
      selectAllText: 'Selekto të gjitha',
      unSelectAllText: 'Fshij të gjitha',
      enableSearchFilter: true,
      classes: "cs-dropdown",
      labelKey: "value",
      primaryKey: "key",
      lazyLoading: true
    };

    this.singleSelect = {
      singleSelection: true,
      text: "Zgjidh",
      selectAllText: 'Selekto të gjitha',
      unSelectAllText: 'Fshij të gjitha',
      enableSearchFilter: true,
      classes: "cs-dropdown",
      labelKey: "value",
      primaryKey: "key",
      lazyLoading: true
    };
  }

  /*
  * update report fields with values from dpgjc
  */
  protected updateValuesByDpgjc() {
    this.form?.controls.patientsName?.setValue(this.dpgjc.firstname);
    this.form?.controls.patientsSurname?.setValue(this.dpgjc.lastname);
    this.form?.controls.fatherName?.setValue(this.dpgjc.father);
    this.form?.controls.idNumber?.setValue(this.dpgjc.nid);
    this.form?.controls.birthday?.setValue(this.dpgjc.birthdate);
    this.form?.controls.birthPlace?.setValue(this.dpgjc.placeOfBirth);
    this.form?.controls.address?.setValue(this.dpgjc.street);
    this.form?.controls.city?.setValue(this.dpgjc.city);
    this.form?.controls.administrativeUnit?.setValue(this.dpgjc.region);
    this.form?.updateValueAndValidity()
  }
  /*
  * update report fields with values from dpt
  */
  protected updateValuesByDpt() {
    let profesion = '';
    if (this.dpt) {
      let dpt = this.dpt.map(item => item.professionCategory).filter((value, index, self) => self.indexOf(value) === index)
      dpt.forEach(element => {
        profesion += element + ', ';
      });

      this.form?.controls.profession?.setValue(profesion.slice(0, -2));
      this.form?.controls.isInsured?.setValue(true);
      this.form?.updateValueAndValidity()
    }
  }

  ngAfterViewInit(): void {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length !== 0) {
      $(".selectpicker").selectpicker({
        iconBase: "nc-icon",
        tickIcon: "nc-check-2"
      });
    }


    if ($(".datepicker").length != 0) {

      $('.datepicker').datetimepicker({
        format: 'dd-mm-yyyy',
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        debug: true
      });
    }
    $(document).ready(function(){
      $('.collapse').on('show.bs.collapse', function (e) {
          $('.collapse').collapse("hide")
      })
  })
  }

  /**
   * Save form values
   */

  groupBy(array, key) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      if (Array.isArray(currentValue[key])) {
        currentValue[key].forEach(el => {
          (result[el] = result[el] || []).push(
              currentValue
          );
        })
      } else {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
      }

      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }

  protected onSave(clearForm: boolean = false) {
    if(clearForm){
      this.form.reset();
    }
    const values = (this.validateDisabled) ? this.form.value : this.form.getRawValue();
    const selectKeys = this.findSelect();
    
    if (this.form.valid) {
      // select keys
      selectKeys[0].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.select);
      });
      // multiple select keys
      selectKeys[1].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.multipleSelect);
      });

      const fileKeys = this.findFile();
      if(fileKeys){
        fileKeys.forEach(element => {
          const selectedElement = this.selectedFiles.find(x => x.formKey === element);
          if(selectedElement) {
            var base64 = selectedElement.selectedFile.split(";base64,");
            values[element] = base64[1];
          }
        });
      }
      
      this.save.emit(values);
    }
    else {
      this.form.markAllAsTouched()
      this.save.emit(null)
    }
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  /**
   * Cancel form values
   */
  protected onCancel() {
    this.cancel.emit(null);
  }


  /**
   * Modify form values
   */
  protected onModify() {

    const values = (this.validateDisabled) ? this.form.value : this.form.getRawValue();
    const selectKeys = this.findSelect();

    if (this.form.valid) {
      // const values = this.form.getRawValue();
      const selectKeys = this.findSelect();
      const fileKeys = this.findFile();

      // select keys
      selectKeys[0].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.select);
      });

      if(fileKeys){
        fileKeys.forEach(element => {
          const selectedElement = this.selectedFiles.find(x => x.formKey === element);
          if(selectedElement) {
            var base64 = selectedElement.selectedFile.split(";base64,");
            if(base64?.length > 1) {
              values[element] = base64[1];
            }else {
              delete values[element];
            }
          } else {
            delete values[element];
          }
        });
      }
      // multiple select keys
      selectKeys[1].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.multipleSelect);
      });
      this.modify.emit(values);
    } else {
      this.modify.emit(null);
      this.form.markAllAsTouched()
    }

 

  }

  /**
   * Draft form values
   */
  protected onDraft() {
    const values = (this.validateDisabled) ? this.form.value : this.form.getRawValue();
    const selectKeys = this.findSelect();

    if (this.form.valid) {
      // const values = this.form.getRawValue();
      const selectKeys = this.findSelect();
      // select keys
      selectKeys[0].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.select);
      });
      // multiple select keys
      selectKeys[1].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.multipleSelect);
      });
      this.draft.emit(values);
    } else {
      this.draft.emit(null);
      this.form.markAllAsTouched()
    }
  }

  /*
  * Check if options Exists
  */
  protected checkArray(values: any[], optionkey: any) {
    if (values) {
      const inArray = values.find(v => v === optionkey);
      if (inArray) {
        return true;
      }
    }
    return null;
  }

  /*
* Find all select fields
*/
  protected findSelect() {
    let selectKeys: string[] = [];
    let selectMultipleKeys: string[] = [];
    this.model.forEach(element => {
      if (element.controlType == ControlType.select) {
        selectKeys.push(element.key)
      } else if (element.controlType == ControlType.multipleSelect) {
        selectMultipleKeys.push(element.key);
      }
    });
    return [selectKeys, selectMultipleKeys];
  }

  /**
   * find select ids
   */
  protected getDropDownSelectIds(values: any, selectType: SelectType): string | string[] {
    let ids: string[] = [];
    if (values) {
      if (selectType == SelectType.select) {
        if (values) {
          return values?.key;
        }
      }
      values.forEach((e: any) => {
        if (e) {
          // ids.push(e.key);
          if (e.key) {
            ids.push(e?.key);
          } else {
            ids.push(e);
          }
        }
      });
    }
    return ids;
  }



  getSelectSettings(item: IForm){
    return {
        singleSelection: (item.controlType === ControlType.select) ? true : false,
        text: "Zgjidh",
        selectAllText: 'Selekto të gjitha',
        unSelectAllText: 'Fshij të gjitha',
        enableSearchFilter: true,
        classes: "cs-dropdown",
        labelKey: "value",
        primaryKey: "key",
        lazyLoading: true,
        disabled: (item.readonly)? true : false
    }
  }

  onSearch(event: any, item) {
    event.stopPropagation();
    const searchText = event.target.value.toLowerCase();
    // Filter the options based on the search text
    const filteredOptions = item.options.filter(option =>
        option.value.toLowerCase().includes(searchText)
    );
    // Update the options in the item
    item.filteredOptions = filteredOptions;
  }

  protected findFile() {
    let fileKeys: string[] = [];
    this.model.forEach(element => {
      if (element.controlType == ControlType.file) {
        fileKeys.push(element.key)
      }
    });
    return fileKeys;
  }

  toggleFileInput() {
    this.showFileInput = true;
  }

  async onFileSelected(event, key) {
    if(this.selectedFiles.findIndex(x => x.formKey === key) != -1) {
      const file = this.selectedFiles.find(x => x.formKey === key);
      file.selectedFile = await this.convertToBase64(event.target.files[0] as File);
    } else {
      this.selectedFiles.push({formKey: key, selectedFile: await this.convertToBase64(event.target.files[0] as File)})
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  // readFile(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     const base64String = e.target.result;

  //     // Here, you can send the base64String to your server or perform any other actions.
  //     // For example, you can use Angular's HttpClient to make an API call to your server.
  //     // this.httpClient.post('your-api-endpoint', { file: base64String })
  //     //   .subscribe(response => {
  //     //     console.log('Server response:', response);
  //     //   });
  //   };

  //   reader.readAsDataURL(file);
  // }
  protected readonly ControlType = ControlType;

  transformDate(date: Date): { year: number, month: number, day: number } {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,  // Months are zero-indexed in JavaScript
      day: date.getDate()
    };
  }
}
