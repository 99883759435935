import { HealthReport } from 'app/configurations/health-report/health-report-model';
import { DPGJCModel, DPTModel, VisitModel } from './../../reports/reports-model';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from './../../services/form.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ControlType } from '../form-component/control-type-enum';
import { IOptions, SelectType } from '../form-component/form-component.component';
import { ReportTypes } from 'app/services/enum.service';
declare var $: any;
export class IReportForm {
  value: any;
  key: string;
  label: string;
  required: boolean;
  controlType: ControlType;
  type: string;
  disabled: boolean;
  options?: IOptions[];
  reportType: ReportTypes[];
  changeMethod?(values, form): any;
  show: boolean;
  placeHolder?: string;
  dropdownSettings?: IDropdownSettings;
  validators?: any;
  tooltip?: string;
  max?: number;
}

@Component({
  selector: 'app-reports-form',
  templateUrl: './reports-form.component.html',
  styleUrls: ['./reports-form.component.scss']
})
export class ReportsFormComponent implements OnInit, AfterViewInit {
  @ViewChild('multipleSelect') dropdown: ElementRef;
  @Input() model: IReportForm[];
  @Input() title: string;
  @Input() hasSave: boolean;
  @Input() hasCancel: boolean;
  @Input() hasModify: boolean;
  @Input() dpgjc: DPGJCModel;
  @Input() dpt: DPTModel[];
  @Input() visit: VisitModel;
  @Input() reportType: ReportTypes;
  @Input() healthReportType: HealthReport;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() modify = new EventEmitter();
  public form: FormGroup;
  protected dropdownSettings = {};
  protected singleSelect = {};
  protected controlType = ControlType;
  @Input() minDate: any;
  @Input() maxDate: any;

  public inputValues: { [key: string]: string } = {};
  public textValues: { [key: string]: string[] } = {};

  constructor(
    private _formService: FormService,
    private _renderer: Renderer2) { }

  ngOnInit() {
    this.form = this._formService.reportFormGroup(this.model, this.reportType);
    this.form.controls.numberOfDays?.setValidators(Validators.min(1));
    this.form.controls['numberOfDays']?.updateValueAndValidity();
    this.form.controls.issuedHealthReportHospitalSigners?.setValidators([Validators.minLength(1), Validators.maxLength(1), Validators.required]);
    if (this.form.controls['diagnoseIcd10s']) {
      this.form.controls['diagnoseIcd10s']?.setValidators([Validators.maxLength(4), Validators.required]);
    }
    this.form.controls['issuedHealthReportHospitalSigners']?.updateValueAndValidity();

    if (this.dpgjc) {
      this.updateValuesByDpgjc();
    }
    if (this.dpt) {
      this.updateValuesByDpt();
    }
    if (this.visit) {
      this.updateValuesByVisit();
    }

    this.dropdownSettings = {
      singleSelection: false,
      text: "Zgjidh",
      selectAllText: 'Selekto të gjitha',
      unSelectAllText: 'Fshij të gjitha',
      enableSearchFilter: true,
      classes: "cs-dropdown",
      labelKey: "value",
      primaryKey: "key",
      lazyLoading: true,
    };

    this.singleSelect = {
      singleSelection: true,
      text: "Zgjidh",
      selectAllText: 'Selekto të gjitha',
      unSelectAllText: 'Fshij të gjitha',
      enableSearchFilter: true,
      classes: "cs-dropdown",
      labelKey: "value",
      primaryKey: "key",
      lazyLoading: true
    };

    let validators;
    if (this.form?.controls?.['healthReportSubType']?.value?.key === 1) { //hera e pare qe behet load ne edit kur eshte paralindje me një fëmijë
      validators = [Validators.required, Validators.min(1), Validators.max(35)]
      this.form.controls['numberOfDays']?.setValidators(validators)
      return
    }
    if (this.form?.controls?.['healthReportSubType']?.value?.key === 2) { //hera e pare qe behet load ne edit kur eshte paralindje me shumë fëmijë
      validators = [Validators.required, Validators.max(60), Validators.min(1)]
      this.form.controls['numberOfDays']?.setValidators(validators)
      return
    }
    if (this.form?.controls?.['healthReportSubType']?.value?.key === 3) { //hera e pare qe behet load ne edit kur eshte paslindje
      validators = [Validators.required, Validators.max(63), Validators.min(1)]
      this.form.controls['numberOfDays']?.setValidators(validators)
      this.form.controls['pregnancyAge']?.setValidators([Validators.min(4), Validators.max(45)]);
      return
    }
    if (this.form?.controls?.['revokeReason']?.value) {
      validators = [Validators.required, Validators.max(55), Validators.min(1)]
      this.form.controls['revokeReason']?.setValidators(validators)
      return
    }
    if (this.form?.controls?.['healthReportSubType']?.value?.key === 4) {
      validators = [Validators.required, Validators.max(30), Validators.min(1)];
      this.form.controls['numberOfDays']?.setValidators(validators)
    }
    this.validateNoDays();
    this.handleTagValues();
  }

  getDropdownSettings(item) {
    return {
      singleSelection: (item.controlType == ControlType.multipleSelect) ? false : true,
      text: "Zgjidh",
      selectAllText: 'Selekto të gjitha',
      unSelectAllText: 'Fshij të gjitha',
      enableSearchFilter: true,
      classes: "cs-dropdown",
      labelKey: "value",
      primaryKey: "key",
      lazyLoading: true,
      disabled: (item.disabled) ? true : false,
      itemsShowLimit: (item.dropdownSettings) ? item.dropdownSettings.itemsShowLimit : 0,
      limitSelection: (item.dropdownSettings) ? item.dropdownSettings.limitSelection : 0,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dpgjc) {
      this.updateValuesByDpgjc();
    }
    if (changes.dpt) {
      this.updateValuesByDpt();
    }
    if (changes.visit) {
      this.updateValuesByVisit();
    }
  }

  /*
  * update report fields with values from dpgjc
  */
  protected updateValuesByDpgjc() {
    this.form?.controls.name?.setValue(this.dpgjc.firstname);
    this.form?.controls.surname?.setValue(this.dpgjc.lastname);
    this.form?.controls.fatherName?.setValue(this.dpgjc.father);
    this.form?.controls.idNumber?.setValue(this.dpgjc.nid);
    this.form?.controls.birthDate?.setValue(this.dpgjc.birthdate);
    if (!this.dpt && this.isKml(this.reportType)) {
      this.form.controls.profession.enable()
    }
    this.form?.updateValueAndValidity()
  }

  /*
  * update report fields with values from dpt
  */
  protected updateValuesByDpt() {
    let profesion = '';
    if (this.dpt) {
      let dpt = this.dpt.map(item => item.professionCategory).filter((value, index, self) => self.indexOf(value) === index)
      dpt.forEach(element => {
        profesion += element + ', ';
      });

      this.form?.controls.profession?.setValue(profesion.slice(0, -2));
      this.form?.controls.isInsured?.setValue(true);
      this.form?.updateValueAndValidity()
    }
  }

  /*
  * update report fields with values from evisit
  */
  protected updateValuesByVisit() {
    let profesion = '';
    if (this.dpt) {
      let dpt = this.dpt.map(item => item.professionCategory).filter((value, index, self) => self.indexOf(value) === index)
      dpt.forEach(element => {
        profesion += element + ', ';
      });
      this.form?.controls.profession?.setValue(profesion);
    }
    this.form?.controls.name?.setValue(this.visit.patientFirstname);
    this.form?.controls.surname?.setValue(this.visit.patientLastname);
    this.form?.controls.fatherName?.setValue(this.visit.patientFatherName);
    this.form?.controls.idNumber?.setValue(this.visit.patientNID);
    this.form?.controls.birthDate?.setValue(this.visit.patientBirthdate);
    this.form?.controls.registerNumber?.setValue(this.visit.registerNr);
    this.form?.controls.isInsured?.setValue(this.visit.isInsured);
  }

  ngAfterViewInit(): void {
    if ($(".selectpicker").length !== 0) {
      $(".selectpicker").selectpicker({
        iconBase: "nc-icon",
        tickIcon: "nc-check-2"
      });
    }

    if ($(".datepicker").length != 0) {

      $('.datepicker').datetimepicker({
        format: 'dd-mm-yyyy',
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        debug: true,
      });
    }
  }

  protected onSave() {
    this.form.updateValueAndValidity();
    const values = this.form.getRawValue();
    const selectKeys = this.findSelect();
    if (this.form.valid) {
      // select keys
      selectKeys[0].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.select);
      });
      // multiple select keys
      selectKeys[1].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.multipleSelect);
      });
      this.save.emit(values);
    }
    else {
      this.form.markAllAsTouched()
    }
  }

  protected onCancel() {
    this.cancel.emit(null);
  }

  protected onModify() {
    if (this.form.valid) {
      const values = this.form.getRawValue();
      const selectKeys = this.findSelect();

      // select keys
      selectKeys[0].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.select);
      });
      // multiple select keys
      selectKeys[1].forEach(element => {
        values[element] = this.getDropDownSelectIds(values[element], SelectType.multipleSelect);
      });
      this.modify.emit(values);
    } else {
      this.modify.emit(null);
      this.form.markAllAsTouched()
    }
  }

  protected checkArray(values: any[], optionkey: any) {
    if (values) {
      const inArray = values.find(v => v === optionkey);
      if (inArray) {
        return true;
      }
    }
    return null;
  }

  public reportIncluded(reportType: ReportTypes, reportTypes: ReportTypes[]) {
    if (this._formService.isReportIncluded(reportType, reportTypes)) {
      return true;
    }
    return false;
  }

  protected findSelect() {
    let selectKeys: string[] = [];
    let selectMultipleKeys: string[] = [];
    this.model.forEach(element => {
      if (this.reportIncluded(this.reportType, element.reportType)) {
        if (element.controlType == ControlType.select) {
          selectKeys.push(element.key)
        } else if (element.controlType == ControlType.multipleSelect) {
          selectMultipleKeys.push(element.key);
        }
      }

    });
    return [selectKeys, selectMultipleKeys];
  }

  protected getDropDownSelectIds(values: any, selectType: SelectType): string | string[] {
    let ids: string[] = [];
    if (values) {
      if (selectType == SelectType.select) {
        if (values) {
          return values.key;
        }
      }
      values.forEach((e: any) => {
        if (e) {
          if (e.key) {
            ids.push(e.key);
          } else {
            ids.push(e);
          }
        }
      });
    }
    return ids;
  }

  protected validateNoDays() {
    if (this.healthReportType) {
      const validators = [Validators.required, Validators.min(this.healthReportType.minDays), Validators.max(this.healthReportType.maxDays)]
      this.form.controls['numberOfDays']?.setValidators(validators);
      this.form.controls['numberOfDays']?.updateValueAndValidity();
    }
  }

  protected isKml(reportType: ReportTypes) {
    switch (reportType) {
      case ReportTypes.PaAftesiKml: {
        return true;
      }
      case ReportTypes.AksidenteSemundjeKml: {
        return true;
      }
      case ReportTypes.PuneTeLehta: {
        return true;
      }
      case ReportTypes.Dite14KuruarJashte: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  onScroll(event: any) { }

  protected readonly event = event;
  protected readonly ControlType = ControlType;

  onInputChange(key: string, value: string) {
    this.inputValues[key] = value;
  }

  addTextValue(key: string) {
    const existing = !!this.textValues[key]?.includes(this.inputValues[key]);
    if (this.inputValues[key].trim()?.length !== 0 && !existing) {
      if (!this.textValues[key]) {
        this.textValues[key] = [];
      }
      this.textValues[key].push(this.inputValues[key]);
      this.inputValues[key] = '';
      const inputElement = document.getElementById(key);
      if (inputElement) {
        
        this._renderer.setProperty(inputElement, 'value', '');
      }
    }
    this.form.get(key).setValue(this.textValues[key]);
    if(this.textValues[key]?.length < 1) {
      this.form.get(key).setErrors({ invalid: true });
    } else {
      this.form.get(key).setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  removeTextValues(key: string, text: string, disabled: boolean) {
    if(!disabled) {
      this.textValues[key] = this.textValues[key].filter(val => val !== text);
      this.form.get(key).setValue(this.textValues[key]);
      if(this.textValues[key]?.length < 2) {
        this.form.get(key).setErrors({ invalid: true });
      } else {
        this.form.get(key).setErrors(null);
      }
      this.form.updateValueAndValidity();
    }
  }

  handleTagValues() {
    const tagValues = this.model.filter(it => it.controlType == ControlType.tags && it.show);
    if(!!tagValues?.length) {
      tagValues.forEach(tag => {
        this.textValues[tag.key] = tag.value
      })
    }
  }

}

