<div class="row card-body">
    <ng-container *ngFor="let item of model">
        <ng-container [ngSwitch]="item.controlType">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="select" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="radio" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="checkbox" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="textarea" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="datepicker" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="6" [ngTemplateOutlet]="multipleSelect" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
            <ng-container *ngSwitchCase="7" [ngTemplateOutlet]="headline" [ngTemplateOutletContext]="{item:item}">
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="card-footer mt-2">
        <button type="button" (click)="filterData()" class="btn btn-primary mb-0 mt-1 fs-1 "><i class="fa fa-search fs-1"></i></button>
        <button type="button" (click)="removeFilters()" class="btn btn-danger mb-0 mt-1 fs-1 "><i class="fa fa-ban fs-1"></i></button>
    </div>
</div>



<!-- Input template -->
<ng-template #input let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2 ml-auto">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group">
            <input class="form-control" [(ngModel)]="item.value" [id]="item.key" (keyup)="chooseFilter(item.key,item.value,item.filterType,item.operationType)" [type]="item.type" [value]="item.value">
        </div>
    </div>
</ng-template>
<!-- End input template -->

<!-- Select template -->
<!-- <ng-template #select let-item='item'>
    <div class="col-sm-12 col-md-6 col-lg-3" [hidden]="item?.hidden">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group">
            <select class="form-control" (change)="(item.changeMethod) ? item.changeMethod($event.target.value) : '' " [id]="item.key" [formControlName]="item.key">
                <option *ngIf="!item.value"></option>
                <ng-container *ngFor="let opt of item.options">
                    <option [attr.selected]="item.value == opt.key ? true : null" [attr.value]="opt.key">
                        {{opt.value}}</option>
                </ng-container>
            </select>
        </div>
    </div>
</ng-template> -->
<!-- End select template -->

<!-- Single Select template -->
<ng-template #select let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group bootstrap-select w-100">
            <ng-multiselect-dropdown [settings]="singleSelect" [data]="item.options" [(ngModel)]="item.key">
            </ng-multiselect-dropdown>
        </div>
    </div>
</ng-template>
<!-- End Single Select template -->


<!-- Textarea template -->
<ng-template #textarea let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group">
            <textarea class="form-control" style="margin-left: auto;" [id]="item.key" (keyup)="(item.changeMethod) ? item.changeMethod($event.target.value,item.key) : '' " [(ngModel)]="item.key">{{item.value}}</textarea>
        </div>
    </div>
</ng-template>
<!-- End textarea template -->

<!-- Radio template -->
<ng-template #radio let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group checkbox-radios" name="item.key" ngDefaultControl [(ngModel)]="item.key">
            <div *ngFor="let opt of item.options" (change)="(item.changeMethod) ? item.changeMethod($event.target.value,item.key) : '' " [id]="opt.key" class="form-check-radio">
                <label class="form-check-label">
                    <input class="form-check-input" [id]="opt.key" [attr.hidden]="item.hidden" [name]="item.key"
                        [type]="item.type" [value]="opt.key"> {{opt.value}}
                    <span class="form-check-sign"></span>
                </label>
            </div>
        </div>
    </div>
</ng-template>
<!-- End radio template -->

<!-- Checkbox template -->
<ng-template #checkbox let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2 " [hidden]="item.hidden">

        <div class="form-group checkbox-radios">
            <div class="form-check mt-3">
                <label class="form-check-label mt-3">
                    <input class="form-check-input"
                        (change)="(item.changeMethod) ? item.changeMethod($event.target.value,item.key,item.filterType,item.operationType) : '' "
                        [id]="item.key" [name]="item.key" type="checkbox" [checked]="item.value" ngDefaultControl
                        [(ngModel)]="item.key"> {{item.label}}
                    <span class="form-check-sign"></span>
                </label>
            </div>
        </div>
    </div>
</ng-template>
<!-- End checkbox template -->

<!-- Datepicker template -->
<ng-template #datepicker let-item='item'>
    <div class="col-sm-6 col-md-4 col-lg-2">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group">
            <input class="form-control" name="dp" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}" #d="ngbDatepicker" (click)="d.toggle()" (keyup)="(item.changeMethod) ? item.changeMethod($event.target.value,item.key,item.filterType,item.operationType) : chooseFilter(item.key,item.value,item.filterType,item.operatonType)"
                name="item.key" (dateSelect)="(item.changeMethod) ? item.changeMethod($event.target.value,item.key,item.filterType,item.operationType) : chooseFilter(item.key,item.value,item.filterType,item.operationType) " [(ngModel)]="item.value">
        </div>
    </div>
</ng-template>
<!-- End datepicker template -->

<!-- Multiple Select template -->
<ng-template #multipleSelect let-item='item'>
    <div class="col-sm-12 col-md-6 col-lg-3">
        <label [attr.for]="item.key">{{item.label}}</label>
        <div class="form-group bootstrap-select w-100">
            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="item.options" [(ngModel)]="item.key">
            </ng-multiselect-dropdown>
        </div>
    </div>
</ng-template>