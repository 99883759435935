import { Component, Input, OnInit } from '@angular/core';
import { ControlType } from 'app/components/form-component/control-type-enum';
import { IOptions } from 'app/components/form-component/form-component.component';
import { FilterType, OperationType } from './filter-control';
declare var $: any;
import _ from 'lodash';
import { Subject } from 'rxjs';
import { FiltersService } from './filter-service';

export class IFilter {
  value: any
  key: string
  label: string
  controlType: ControlType
  operationType?: OperationType
  filterType: FilterType
  type: string
  options?: IOptions[]
  changeMethod?(values, keys, filterType, options?, operationType?): any;
  hidden?= false;
}
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})

export class FiltersComponent implements OnInit {
  @Input() model: IFilter[];
  @Input() source: any[]
  filteredSource: any[]
  protected controlType: ControlType;
  public filtereredData;
  activeFilter = {}

  constructor(private filterService: FiltersService) { }

  ngOnInit(): void {
    this.filteredSource = [...this.source]
    this.filtereredData = new Subject<any[]>()

  }
  ngOnChanges() {
  }

  ngAfterViewInit(): void {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length !== 0) {
      $(".selectpicker").selectpicker({
        iconBase: "nc-icon",
        tickIcon: "nc-check-2"
      });
    }


    if ($(".datepicker").length != 0) {

      $('.datepicker').datetimepicker({
        format: 'dd-mm-yyyy',
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        debug: true
      });
    }
  }

  public chooseFilter(key, value, filterType: FilterType, operation: OperationType) {
    switch (filterType) {
      case FilterType.DateCheck:
        if (value || value !== null) {
          if (operation === OperationType.LessThan) {
            this.activeFilter[key] = (val: Date) => { return new Date(val) > new Date(value) || new Date(val).getFullYear() === new Date(value).getFullYear() && new Date(val).getMonth() === new Date(value).getMonth() && new Date(val).getDate() === new Date(value).getDate() }
          }
          if (operation === OperationType.GreaterThan) {
            this.activeFilter[key] = (val: Date) => { return new Date(val) <  new Date(value) || new Date(val).getFullYear() === new Date(value).getFullYear() && new Date(val).getMonth() === new Date(value).getMonth() && new Date(val).getDate() === new Date(value).getDate()}
          }
          if (operation === OperationType.Equal) {
            this.activeFilter[key] = (val: Date) => { return new Date(val).getFullYear() === new Date(value).getFullYear() && new Date(val).getMonth() === new Date(value).getMonth() && new Date(val).getDate() === new Date(value).getDate() };
          }
        } else {
          this.removeFilter(key)
        }
        break
      case FilterType.startsWith:
        if (value) {
          this.activeFilter[key] = val => val.toString().toLowerCase().includes(value.toString().toLowerCase());
        }
        break

      default:
    }
  }
  filterData() {

    this.filterService.applyFilters(this.source, this.filteredSource, this.activeFilter)

  }

  removeFilter(key) {
    delete this.activeFilter[key]
  }

  removeFilters() {
    this.model.forEach(item => { item.value = '';  })
    this.activeFilter = {}
    this.source = _.filter(this.filteredSource, _.conforms(this.activeFilter));
    this.filterService.applyFilters(this.source, this.filteredSource, this.activeFilter);

  }


}
