export enum FilterType {
    startsWith,
    Excact,
    DateCheck,
    Boolean
}




export enum OperationType {
  Equal,
  LessThan,
  GreaterThan,
}